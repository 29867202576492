<template>
 <div>

        <b-card bg-variant="light">
          <b-form-group
            label="Stage"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="selected"
              :options="options"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="sm"
              name="radio-btn-outline"
              buttons
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group 
            label-cols-lg="3"
            label="Product Item"
            label-size="lg"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >

            <b-form-group
              label="Description:"
              label-for="nested-description"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input id="nested-description"
                v-model="json_ams.attributes.description"
              ></b-form-input>
            </b-form-group>

          <b-form-group
              label="Serial / Copy #:"
              label-for="nested-serial-number"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input id="nested-serial-number" type="number"
                v-model="json_ams.attributes['serial-number']"
              ></b-form-input>
            </b-form-group>

          <b-form-group
              label="Purchased Price :"
              label-for="nested-purchased-price"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_estimate'"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-purchased-price"
                  v-model="json_ams.attributes['purchased-price-cents']"
                >
                </b-form-input-currency>
              </b-input-group>
            </b-form-group>
          
          <b-form-group
              label="Estimated Min Price :"
              label-for="nested-estimated-min-price"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_estimate'"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-estimated-min-price"
                  v-model="json_ams.attributes['estimated-min-price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Estimated Max Price :"
              label-for="nested-estimated-max-price"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_estimate'"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-estimated-max-price"
                  v-model="json_ams.attributes['estimated-max-price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>

          <b-form-group
              label="Authenticated Min Price :"
              label-for="nested-authenticated-min-price"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_authorize'"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-authenticated-min-price"
                  v-model="json_ams.attributes['authenticated-min-price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>

          <b-form-group
              label="Authenticated Max Price :"
              label-for="nested-authenticated-max-price"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_authorize'"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-authenticated-max-price"
                  v-model="json_ams.attributes['authenticated-max-price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>

          <b-form-group
              label="Asking Price :"
              label-for="nested-asking-price"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-asking-price"
                  v-model="json_ams.attributes['asking-price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>
          <b-form-group
              label="Sold Price :"
              label-for="nested-sold-price"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_sell'"
            >
              <b-input-group size="sm" prepend="$">
                <b-form-input-currency
                  id="nested-sold-price"
                  v-model="json_ams.attributes['sold-price-cents']"
                ></b-form-input-currency>
              </b-input-group>
            </b-form-group>

          <b-form-group
              label="Estimated Quality :"
              label-for="nested-estimated-quality"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_estimate'"
            >
              <b-input-group size="sm" prepend="%" :append="`${json_ams.attributes['estimated-quality']/100}`">
                <b-form-input
                  id="nested-estimated-quality"
                  v-model="json_ams.attributes['estimated-quality']"
                  type="range" min="0" max="10000"
                ></b-form-input>
              </b-input-group>
          </b-form-group>

          <b-form-group
              label="Authenticated Quality :"
              label-for="nested-authenticated-quality"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_authorize'"
            >
              <b-input-group size="sm" prepend="%" :append="`${json_ams.attributes['authenticated-quality']/100}`">
                <b-form-input
                  id="nested-authenticated-quality"
                  v-model="json_ams.attributes['authenticated-quality']"
                  type="range" min="0" max="10000"
                ></b-form-input>
              </b-input-group>
          </b-form-group>

          <b-form-group
              label="Collected At:"
              label-for="nested-collected_at"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_sell'"
            >
            <b-calendar id="nested-collected-at" v-model="json_ams.attributes['collected-at']" locale="en-US"></b-calendar>
          </b-form-group>
            
          <b-form-group
              label="Prepared At:"
              label-for="nested-prepared-at"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_sell'"
            >
            <b-calendar id="nested-prepared-at" v-model="json_ams.attributes['prepared-at']" locale="en-US"></b-calendar>
          </b-form-group>

          <b-form-group
              label="Delivered At:"
              label-for="nested-delivered-at"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_ship'"
            >
            <b-calendar id="nested-delivered-at" v-model="json_ams.attributes['delivered-at']" locale="en-US"></b-calendar>
          </b-form-group>

          <b-form-group
              label="Received At:"
              label-for="nested-received-at"
              label-cols-sm="3"
              label-align-sm="right"
              v-show="selected === 'display_ship'"
            >
            <b-calendar id="nested-received-at" v-model="json_ams.attributes['received-at']" locale="en-US"></b-calendar>
          </b-form-group>

          </b-form-group>
        </b-card>

 </div>
</template>

<script>
import BFormInputCurrency from './BFormInputCurrency.vue'

export default {
  components: {
    BFormInputCurrency,
  },
  props: {
    json_ams: {
      Type: Object
    }
  },
  data () {
    return {
      selected: 'display_estimate',
      options: [
        { text: 'Estimate', value: 'display_estimate' },
        { text: 'Authorize', value: 'display_authorize' /*, disabled: true */ },
        { text: 'Sell', value: 'display_sell'},
        { text: 'Ship', value: 'display_ship' }
      ]
    }
  },
  methods: {
    display(selected) {
      return selected === this.selected
    }
  }
}
</script>