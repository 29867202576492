<template>
  <b-form-input type="text" v-model="displayValue" :state="myState" />
</template>

<script>
export default {
  props: {
    value: {
      Type: Number
    },
  },
  data () {
    return {
      oldValue: undefined,
      state: true
    }
  },
  computed: {
    myState () {
      return this.state ? true : false
    },
    displayValue: {
      get: function() {
        let dollars_and_cents = this.value / 100
        return dollars_and_cents.toString()
      },
      set: function(modifiedValue) {
        if (String(modifiedValue).split('.')[1]?.length > 2) {
          alert('Should not input number over 2 decimal places')
          this.$emit('input', this.oldValue)
          this.state = false
        }
        else {
          let newValue = Math.round(modifiedValue * 100)
          // Ensure that it is not NaN
          if (isNaN(newValue)) {
            newValue = 0
            this.state = false
          }
          else {
            this.oldValue = newValue
            this.state = true
          }
          // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
          // $emit the event so that parent component gets it
          this.$emit('input', newValue)
        }
      }
    }
  }
}
</script>