<template>
  <div>
    <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      indicators
      img-width="261"
      img-height="120"
      v-model="slide"
    >
      <b-carousel-slide
        v-for="(item, index) in attr_images" :key="item.id"
        :caption="imageCaption(index)" 
        :img-src="imageUrl(index)"
      >
      </b-carousel-slide>
    </b-carousel>
    <b-row>
      <b-col sm="5" md="8" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          pills
          align="fill"
          class="my-1"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col b-col sm="5" md="4" class="my-1">
      Total {{totalRows}}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
      api_route: 'api/admin/v1/collection_images',
      included: [],
      attr_images: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      slide: 0
    }
  },
  props: {
    filtering: {
      Type: Object
    },
    refresh: {
      Type: Boolean
    },
    per_page_setting: {
      Type: Number
    }
  },
  methods: {
    imageCaption(index) {
      return this.attr_images[index].id
    },
    imageUrl(index) {
      return this.attr_images[index].attributes['image-url']
    },
    doRefresh() {
      this.emit('refreshed', true)
    },
    callApi () {
      http_v1.get(this.api_route, {
        params: {
            page: this.currentPage,
            per_page: this.per_page_setting,
            ... this.filtering
        }
      }).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.attr_images = response.data.data
          this.included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    }
  },
  mounted() {
    this.callApi()
  },
  watch: {
    refresh(val) {
      if(val === true) {
        this.currentPage = 1
        this.callApi()
        this.$emit('refreshed', val)
      }
    },
    currentPage() {
      this.callApi()
    }
  }
}
</script>
