<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-row>
      <b-col md="6">
        <product-item-show :id="id" @gotAttrs="assignAttrs"></product-item-show>
      </b-col>
      <b-col md="6">
        <collection-image-index
          :filtering="filtering"
        ></collection-image-index>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ProductItemShow from './ProductItemShow.vue'
import CollectionImageIndex from './CollectionImageIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    ProductItemShow,
    CollectionImageIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        product_item_ids: this.id
      },
      ams_template: {
        type: 'product-models',
        description: undefined,
        attributes: {
          'brand-id': this.id
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description
    }
  }
}
</script>